import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './styles/index.css'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)




console.log("Hello nosy developer 🙋🏾‍♂️ or tester, my name is Donnell and welcome to my portfolio 📚")