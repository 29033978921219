import SyntaxHighlighter from 'react-syntax-highlighter'
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import createElement from 'react-syntax-highlighter/dist/esm/create-element'
import { useWindowSize } from '../../../../hooks/screenSize'

const codeString = `/*
  author: Donnell Zachary Reuben
  description: Experiences & Projects
*/

let Experiences = [
  {
    company: "Lemonade",
    title: "Senior QA",
    time: "July 2023 - Present",
    location: "Chicago, IL",
    tasks: [
     "Developed and implemented a comprehensive testing framework for (Citrus) internal application utilized 
      by synthetic agents",
      "Designed automated test suites to ensure reliability and performance of the agent-facing systems",
      "Established quality assurance protocols and documentation for cross-team collaboration",
      "Led integration testing initiatives to validate synthetic agent interactions with core systems"
    ]
  },
  {
    company: "DinkLabs",
      title: "Founder",
      time: "May 2024 - Present",
      location: "Chicago, IL",
      tasks: [
      "Developing app & website",
      "Closed beta platform for private pickleball lessons with Donnell Reuben",
      "Any DinkLab related inquiries please email dreuben@dinklabs.io"
      ]
  },
  {
    company: "Kyte",
    title: "SDET",
    time: "May 2021 - July 2023",
    location: "Chicago, IL",
    tasks: [
      "Maintained BDD Cucumber-JUnit framework using Java, Page Object Model, and Singleton patterns",
      "Integrated custom test automation suite for “Kyte Surfer”, ensuring consistent quality",
      "Reduced manual testing by 50% through JDBC-MySQL integration and Selenium WebDriver",
      "Automated Jenkins pipelines on AWS EC2, improving bug discovery rate by 63%",
      "Increased test coverage by 40% while maintaining 75%+ regression pass rates"
    ]
  },
  {
    company: "Carvana",
    title: "QA",
    time: "March 2019 - March 2021",
    location: "Chicago, IL",
    tasks: [
      "Utilized Java Selenium WebDriver-Cucumber-JUnit framework for developing comprehensive test cases with Gherkin syntax",
      "Engineered complex SQL queries for PostgreSQL and Oracle databases using JDBC, optimizing query performance by 30%",
      "Performed API testing manually with Postman and automatically with RestAssured library",
      "Conducted parallel cross-browser testing on AWS virtual servers using Selenium Grid and Jenkins CI/CD pipelines
    ]
  }
]

let projects = {
  work: [
    {
      name: "DinkLabs app",
      repository: "private"
    }
  ],
  personal: [
    {
      name: "Instagram Clone",
      repository: "https://github.com/donnellreuben/INSTAGRAM-CLONE"
    },
    {
      name: "PDF Renderer",
      repository: "https://github.com/donnellreuben/Render-PDF-App"
    },
    {
      name: "3D cube",
      description: "Just a simple 3D cube with custom controls",
      repository: "https://github.com/donnellreuben/Fullscreen-Resizing/tree/main"
    },
    {
      name: "Twitter clone",
      description: "a twitter clone with an animated sidebar made with rails",
      repository: "https://github.com/donnellreuben/Twitter-Clone"
    }
  ]
}
`

const WorkExperience = ({ padding = `10px` }) => {
  const windowSize = useWindowSize()

  // https://github.com/react-syntax-highlighter/react-syntax-highlighter/issues/104
  // Massive thanks to me, myself, and I
  const rowRenderer = ({ rows, stylesheet, useInlineStyles }) => {
    return rows.map((node, i) => {
      node.children = node.children.map((children) => {
        const text = children?.children?.[0]?.value

        if (typeof text === 'string' && text.startsWith('"http')) {
          return {
            ...children,
            tagName: 'a',
            properties: {
              ...children.properties,
              href: text.slice(1, -1), // in JSON strings are enclosed with ", they need to be removed,
              target: '_blank',
              style: { textDecoration: 'underline' }
            }
          }
        }

        return children
      })

      return createElement({
        node,
        stylesheet,
        useInlineStyles,
        key: `code-segement${i}`
      })
    })
  }

  return (
    <SyntaxHighlighter
      language="javascript"
      style={atomOneDark}
      renderer={rowRenderer}
      showLineNumbers={windowSize.width >= 1024}
      wrapLongLines
      customStyle={{ backgroundColor: 'rgba(40, 44, 52, 0.8)', padding }}
    >
      {codeString}
    </SyntaxHighlighter>
  )
}
export default WorkExperience
